






























import Vue, { PropType } from "vue";
import ActionListItem from "@/components/ActionListItem.vue";
import UserName from "@/components/UserName.vue";
import RightArrowIcon from "@/icons/RightArrow.vue";

interface ActionListItemAction {
  kind: string;
  title: string;
  action: () => Promise<void>;
}

export default Vue.extend({
  name: "MessageItem",
  components: {
    ActionListItem,
    RightArrowIcon,
    UserName
  },
  props: {
    message: {
      type: Object as PropType<Messages.UserFeedback>,
      required: true
    }
  },
  data: () => ({
    error: null as string | Error | null,
    cachedMessage: null as Messages.UserFeedback | null, // used while deleting so we retain visuals
    isWorking: false
  }),
  computed: {
    actions(): ActionListItemAction[] {
      if (this.isArchived) {
        // Buttons if archived
        return [
          {
            kind: "danger",
            title: "Delete",
            action: this.deleteMessage.bind(this)
          },
          {
            kind: "primary",
            title: "Unarchive",
            action: this.toggleArchive.bind(this)
          }
        ];
      }
      // Buttons if not archived
      return [
        {
          kind: "primary",
          title: "Archive",
          action: this.toggleArchive.bind(this)
        }
      ];
    },
    contentPreview(): string {
      if (!this.message.content) {
        return "<Empty Message>";
      }
      const { characterLimit } = this;
      if (this.message.content.length > characterLimit) {
        return this.message.content.substring(0, characterLimit - 3) + "...";
      }
      return this.message.content;
    },
    characterLimit(): number {
      return 120;
    },
    isArchived(): boolean {
      if (!this.message && !this.cachedMessage) {
        return false;
      }
      const message = this.cachedMessage || this.message;
      return message.isArchived;
    }
  },
  methods: {
    async toggleArchive(): Promise<void> {
      if (!this.message || !this.message.id || this.isWorking) {
        return;
      }

      this.error = null;
      this.isWorking = true;

      try {
        await this.$store.direct.dispatch.setMessageArchived({
          messageId: this.message.id,
          isArchived: !this.isArchived
        });
      } catch (error) {
        if ((error as FirebaseError).code == "permission-denied") {
          this.error = "You don't have permission to archive that message.";
        } else {
          this.error = `Archive failed: ${error as string}`;
        }
        console.error(`Failed to archive message ${this.message.id}:`, error);
      }
      this.isWorking = false;
    },
    async deleteMessage(): Promise<void> {
      if (!this.message || !this.message.id || this.isWorking) {
        return;
      }
      if (!confirm(`Are you sure you want to delete this message?`)) {
        return;
      }
      this.error = null;
      this.isWorking = true;
      this.cachedMessage = this.message;

      try {
        await this.$store.direct.dispatch.deleteMessage(this.message.id);
      } catch (error) {
        if ((error as FirebaseError).code === "permission-denied") {
          this.error = "You don't have permission to delete that message.";
        } else {
          this.error = `Failed to delete: ${error as string}`;
        }
        console.error(`Failed to delete message ${this.cachedMessage.id}:`, error);
        // We alert, because vuex deletes the view just before the server gets back with a failure.
        alert(this.error);
      }
      this.isWorking = false;
      this.cachedMessage = null;
    }
  }
});
