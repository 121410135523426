

































import Vue from "vue";
import { Getters } from "@/store";
import { db } from "@/firebase";
import orderBy from "lodash/orderBy";

import ListFirestore from "@/components/ListFirestore.vue";
import MessageItem from "@/components/MessageItem.vue";
import RightArrowIcon from "@/icons/RightArrow.vue";

export default Vue.extend({
  name: "AllMessages",
  components: {
    ListFirestore,
    MessageItem,
    RightArrowIcon
  },
  data: () => ({
    isEditing: false
  }),
  computed: {
    getters(): Getters {
      return this.$store.direct.getters;
    },
    messages(): readonly Messages.UserFeedback[] {
      return this.getters.unarchivedMessages;
    },
    archiveCount(): number {
      return this.getters.archivedMessagesCount;
    },
    messagesCount(): number {
      return this.getters.unarchivedMessagesCount;
    }
  },
  methods: {
    getMessagesCollection(): FirestoreQuery {
      return db
        .collection("global-admin")
        .doc("userFeedbackMessages")
        .collection("messages")
        .where("isArchived", "==", false)
        .orderBy("sentAt", "desc");
    },
    orderList<T>(items: Record<string, T>): T[] {
      return orderBy(Object.values(items), "sentAt", "desc");
    },
    toggleEditing(): void {
      this.isEditing = !this.isEditing;
    }
  }
});
