






































import Vue from "vue";
import ActionButton from "./ActionButton.vue";

export default Vue.extend({
  name: "ActionListItem",
  components: {
    ActionButton
  },
  props: {
    title: { type: String, default: null },
    subtitle: { type: String, default: null },
    trailingTitle: { type: String, default: null },
    isWorking: { type: Boolean, default: false },
    actions: {
      type: Array, // ActionItem[]
      default: () => []
    }
  },
  data: () => ({
    isOptionsOpen: false, // Whether we show options buttons
    recentlyOpened: null as NodeJS.Timeout | null // Resets isOptionsOpen after a timeout
  }),
  watch: {
    isOptionsOpen(newValue) {
      if (this.recentlyOpened !== null) {
        // The user just toggled options. Forget the old timeout.
        clearTimeout(this.recentlyOpened);
      }
      if (newValue) {
        // if we've just opened options...
        this.recentlyOpened = setTimeout(() => {
          this.isOptionsOpen = false;
          this.recentlyOpened = null;
        }, 5000); // reset after 5s
      }
    }
  },
  methods: {
    toggleOptions() {
      this.isOptionsOpen = !this.isOptionsOpen;
    }
  }
});
